import { useTable } from "react-table";
import React from "react";

// @ts-ignore
export function Table({ columns, data }) {
	// Use the state and functions returned from useTable to build your UI
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
	} = useTable({
		columns,
		data,
	})

	// Render the UI for your table
	return (
		<table {...getTableProps()}>
		<thead>
			{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
					{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render('Header')}</th>
						))}
					</tr>
				))}
		</thead>
		<tbody {...getTableBodyProps()}>
	{rows.map(row => {
		prepareRow(row)
		return (
			<tr {...row.getRowProps()}>
			{row.cells.map(cell => {
					return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
				})}
			</tr>
		)
	})}
	</tbody>
	</table>
)
}
