import React from 'react';
import { HomeWrapper } from "../styled.components";


const Home = () => {
	return (
		<HomeWrapper>
			<h1>ZT Bank Demo</h1>
		</HomeWrapper>
	);
};

export default Home;
